export type LogChange = {
    destination?: 'QBO' | 'ACOMBA';
    images?: string[];
    description: string[] | string;
    title?: string;
};

export type LogsByLanguage = {
    fixes?: (LogChange | string)[];
    improvements?: (LogChange | string)[];
    newFeatures?: (LogChange | string)[];
};

export type ChangeLogEntry = {
    date: number; // timestamp
    fr: LogsByLanguage;
    en: LogsByLanguage;
};

export const logs: ChangeLogEntry[] = [
    {
        date: new Date('January 26, 2024').getTime(),
        fr: {
            improvements: [
                {
                    description: 'Certains boutons ont été regroupés dans un menu déroulant appelé "Actions groupées".',
                    images: ['/changelogsimgs/fr-bulkactions-260124.png'],
                },
            ],
            newFeatures: [
                {
                    description:
                        "Possibilité de sélectionner des commandes et demander de l'aide directement via l'interface.",
                    images: ['/changelogsimgs/fr-askhelp-260124.png'],
                },
                {
                    description: 'Possibilité de rechercher dans les mappings (Clients, Produits, Taxes, Termes...).',
                },
                {
                    description: 'Possibilité de reset un mapping.',
                },
                {
                    description:
                        "Possibilité de créer un client via l'interface si celui-ci n'a pas pu être mappé automatiquement. Pour le cas de Acomba, l'identifiant du client dans la base de données et d'autres champs suivent une logique particulière afin de respecter leurs limitations.",
                    images: ['/changelogsimgs/fr-resetandcreatecustomer-260124.png'],
                },
                {
                    description: "Possibilité de recharger les details d'un mapping depuis PivoHub.",
                    images: ['/changelogsimgs/fr-updatemappinglists-260124.png'],
                },
                {
                    destination: 'ACOMBA',
                    description: [
                        "Possibilité de connaître l'état de l'agent (connecté ou non) via l'interface. pour un meilleur resulta vous pouvez mettre à jour l'agent a la version 1.2.2",
                        "Possibilité de connaître la version de l'agent. Si celui-ci est trop vieux, un pop up apparaîtra indiquant qu'il l'est.",
                    ],
                    images: [
                        '/changelogsimgs/fr-agentconnected-260124.png',
                        '/changelogsimgs/fr-agentnotconnected-260124.png',
                    ],
                },
            ],
        },
        en: {
            improvements: [
                {
                    description: 'Some buttons have been grouped into a dropdown menu called "Grouped Actions".',
                    images: ['/changelogsimgs/bulkactions-260124.png'],
                },
            ],
            newFeatures: [
                {
                    description: 'Ability to select orders and ask for help directly through the interface.',
                    images: ['/changelogsimgs/askhelp-260124.png'],
                },
                {
                    description: 'Ability to search in mappings (Customers, Products, Taxes, Terms...). ',
                },
                {
                    description: 'Ability to reset a mapping.',
                    images: ['/changelogsimgs/resetandcreatecustomer-260124.png'],
                },
                {
                    description:
                        'Ability to create a client via the interface if it could not be mapped automatically. For Acomba, the client ID in the database and other fields follow a specific logic to respect their limitations.',
                },
                {
                    description: 'Ability to reload the details of a mapping from PivoHub.',
                    images: ['/changelogsimgs/updatemappinglists-260124.png'],
                },
                {
                    destination: 'ACOMBA',
                    description: [
                        'Ability to know the status of the agent (connected or not) via the interface. For better stability please update the agent to v1.2.2',
                        'Ability to know the version of the agent. If it is too old, a pop up will appear indicating that it is.',
                    ],
                    images: [
                        '/changelogsimgs/agentconnected-260124.png',
                        '/changelogsimgs/agentnotconnected-260124.png',
                    ],
                },
            ],
        },
    },
    {
        date: new Date('December 5, 2023').getTime(),
        fr: {
            fixes: [
                {
                    description:
                        'Corrigé un bug où un mapping oublie de notifier les commandes qui attendent après lui. Exemple: Commande 15 à besoin du produit "banane" normalement lorsque le produit "banane" est mappé la commande se met à jour pour permettre le transfert mais il y avait un bug sur ce point qui faisait en sorte que les commandes n\'étaient jamais mises à jour',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description:
                        'Fix a bug where a mapping forgets to notify the order waiting for it. Example: Order 15 needs the product "banana" Normally, when the "banana" product is mapped, the order updates to enable the transfer. However, there was a bug at this point that prevented the order from being updated',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: new Date('November 27, 2023').getTime(),
        fr: {
            fixes: [
                {
                    description: '[QBO] Corrigé le bug où une commande contenant juste des escompte tombait en erreur',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: '[QBO] corrected a bug where a discount only order fails during transfer',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: new Date('November 23, 2023').getTime(),
        fr: {
            fixes: [
                {
                    description:
                        '[QBO] les factures négatives sont maintenant supportées, elles sont rentrées en tant que mémo de crédit',
                },
            ],
            improvements: [],
            newFeatures: [
                {
                    description: 'support pour la version 8.2 de pivohub',
                },
            ],
        },
        en: {
            fixes: [
                {
                    description:
                        '[QBO] invoices with negative totals are now supported, they get saved as a credit memo',
                },
            ],
            improvements: [],
            newFeatures: [
                {
                    description: 'support for version 8.2 of pivohub',
                },
            ],
        },
    },
    {
        date: new Date('October 26, 2023').getTime(),
        fr: {
            fixes: [
                {
                    description:
                        "[ACOMBA] la date de la facture transférée est désormais la date de livraison, si la date de livraison n'est pas disponible la date de création sera utilisée",
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description:
                        '[ACOMBA] the invoice date transferred is now the delivery date, if the delivery date is not available progylink will use the creation date',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: new Date('October 19, 2023').getTime(),
        fr: {
            fixes: [],
            improvements: [
                {
                    description:
                        '[QBO] les notes Fournisseurs et les notes détaillants sont désormais transférées dans la facture QBO',
                },
            ],
            newFeatures: [],
        },
        en: {
            fixes: [],
            improvements: [
                {
                    description: '[QBO] the supplier note and retailer note are now transferred to the QBO Order',
                },
            ],
            newFeatures: [],
        },
    },
    {
        date: new Date('October 3, 2023').getTime(),
        fr: {
            fixes: [
                {
                    description: 'Corriger le comportement sur le bouton du mode automatique',
                },
                {
                    description:
                        "[AVIS][Acomba] Pour s'assurer du bon fonctionnement de la mise à jour, veuillez mettre à jour votre agent à la version 1.2.0",
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: 'Corrected bug that prevented modifications on automatic/manual button to be saved',
                },
                {
                    description:
                        '[NOTICE][Acomba] To ensure proper functioning of the update, please update your agent to version 1.2.0',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: new Date('August 30, 2023').getTime(), // 30 Août 2023
        fr: {
            fixes: [
                {
                    description: [
                        "[AVIS][Acomba] Pour s'assurer du bon fonctionnement de la mise à jour, veuillez mettre à jour votre agent à la version 1.1.8",
                        '[Acomba] corriger le bug qui fait en sorte que les taxes et le montant sont recalculés par Acomba lors de la conversion en facture',
                    ],
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: [
                        '[NOTICE][Acomba] To ensure proper functioning of the update, please update your agent to version 1.1.8',
                        '[Acomba] fixed the bug that causes Acomba to recalculate taxes and amounts during invoice conversion',
                    ],
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: new Date('August 4, 2023').getTime(), // 4 Août 2023
        fr: {
            fixes: [
                {
                    description:
                        '[QBO] corriger le bug qui fait les commandes tomber en erreur lorsque le nom du distributeur est trop long. Ce champ est désormais tronqué à 29 caractères pour ne pas dépasser la limite de QBO',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description:
                        '[QBO] corrected bug that failed order because the distributor name is too long. Now the distributor name is truncated at 29 characters so it does not exceed the limit set by QBO',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: new Date('August 1, 2023').getTime(), // 1 Août 2023
        fr: {
            fixes: [
                {
                    description: [
                        "[Acomba] réglé le bug où Acomba recalcule les prix sur les produits escomptés (à condition que le produit dans Acomba ait un prix de 1$); exemple: dans le cas d'une commande où il y a un rabais de 127.1$ le résultat sur Acomba sera : (numéroProduit) <numéro du produit d'escompte> | (quantitéCommandée) -127.1 | (prix unitaire) 1 | (sous-total) -127.1",
                        "[AVIS][Acomba] pour s'assurer la validité des prix, il faut que les prix sur Pivo et les prix sur Acomba soient les mêmes, sinon Acomba va recalculer le prix lors de la création de la facture",
                    ],
                },
            ],
            improvements: [
                {
                    description: '[Acomba] il y a maintenant des lignes vides qui séparent les produits',
                },
            ],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: [
                        '[Acombawork the discount product must have a price of 1$); example: If my order has a discount of 127.1$ the resulting line in the order will be: (productNumber) <number of the discount product> | (orderedQuantity) -127.1 | (selling Price) 1 |(subTotal) -127.1',
                        '[NOTICE][Acomba] to make sure Acomba calculate prices correctly on the products. The price on Pivohub Must be equal to the price in Acomba. If not Acomba will overwrite the price during the transfer to an invoice.',
                    ],
                },
            ],
            improvements: [
                {
                    description: '[Acomba] there is now an empty line between products',
                },
            ],
            newFeatures: [],
        },
    },
    {
        date: new Date('June 27, 2023').getTime(), // 27 Juin 2023
        fr: {
            fixes: [
                {
                    description: "L'adresse des clients est maintenant affichée correctement",
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: 'Customer address is now displayed correctly',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: new Date('June 2, 2023').getTime(), // 2 Juin 2023
        fr: {
            fixes: [
                {
                    description: [
                        'Les produits et clients sont triés en ordre alphabétique dans les menus déroulants',
                        "Il est de nouveau possible de sélectionner les commandes visibles en cliquant sur la case à cocher dans l'entête de la page de Commandes",
                    ],
                },
            ],
            improvements: [
                {
                    description: [
                        'Les catégories des items de QBO sont maintenant visibles dans la liste des produits',
                        'Il est maintenant possible de trouver un client par son adresse',
                    ],
                },
            ],
            newFeatures: [
                {
                    description:
                        'Un bouton "rafraîchir les commandes" a été ajouté pour éviter de devoir rafraîchir l\'application au complet pour obtenir les nouvelles commandes',
                },
            ],
        },
        en: {
            fixes: [
                {
                    description: [
                        'Products and customers are now sorted alphabetically in dropdown menus',
                        'It is again possible to select visible orders by checking the box in the header of the Orders page',
                    ],
                },
            ],
            improvements: [
                {
                    description: [
                        'QBO item categories are now visible in the product list',
                        'It is now possible to find a customer by their address',
                    ],
                },
            ],
            newFeatures: [
                {
                    description:
                        'A "Refresh orders" button has been added to avoid having to refresh the entire application to get new orders.',
                },
            ],
        },
    },
    {
        date: new Date('May 23, 2023').getTime(), // 23 Mai 2023
        fr: {
            fixes: [
                {
                    description:
                        "Le statut de la commande se met maintenant à jour correctement lorsqu'elle est transférée ou ignorée",
                },
            ],
            improvements: [],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: 'Order status now updates correctly when transferred or ignored',
                },
            ],
            improvements: [],
            newFeatures: [],
        },
    },
    {
        date: 1684434524000, // 18 Mai 2023
        fr: {
            fixes: [
                {
                    description:
                        'Les nouvelles commandes ne seront pas récupérées automatiquement et en continu, cela posait des problèmes. Vous devrez rafraîchir la page pour les obtenir.',
                },
            ],
            improvements: [
                {
                    description: [
                        'Temps de réponse plus rapide lors du chargement des valeurs de mappage',
                        "La politique de confidentialité et les conditions d'utilisation sont maintenant disponibles dans le pied de page",
                    ],
                },
            ],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description:
                        'New orders wont be fetched automatically and continuously, it was causing problems. You will need to refresh the page to get them.',
                },
            ],
            improvements: [
                {
                    description: [
                        'Quicker response time when loading mapping values',
                        'Privacy policy and terms of use are now available in the footer',
                    ],
                },
            ],
            newFeatures: [],
        },
    },
    {
        date: new Date('May 2, 2023').getTime(), // 2 Mai 2023
        fr: {
            fixes: [
                {
                    description: "Plusieurs petites corrections au flux de travail de l'application",
                },
            ],
            improvements: [
                {
                    description: 'La pagination dans la liste des commandes a été remplacée par un défilement infini',
                },
            ],
            newFeatures: [
                {
                    description: 'Ajout du support pour Acomba',
                },
            ],
        },
        en: {
            fixes: [
                {
                    description: 'Many small fixes to application workflow',
                },
            ],
            improvements: [
                {
                    description: 'Pagination in the orders list was replaced with an infinite scroll',
                },
            ],
            newFeatures: [
                {
                    description: 'Added support for Acomba',
                },
            ],
        },
    },
    {
        date: new Date('April 20 2023').getTime(), // 20 Avril 2023
        fr: {
            fixes: [],
            improvements: [
                {
                    description:
                        'Le courriel utilisé dans QBO lors de la création des factures est maintenant celui obtenu du dossier client QBO au moment du transfert.',
                },
            ],
            newFeatures: [],
        },
        en: {
            fixes: [],
            improvements: [
                {
                    description:
                        'The email used in QBO when creating invoices is now the one obtained from the QBO customer file at the time of the transfer.',
                },
            ],
            newFeatures: [],
        },
    },
    {
        date: new Date('February 7 2023').getTime(), // 7 Février 2023
        fr: {
            fixes: [
                {
                    description: 'Meilleure gestion des erreurs lors du traitement des commandes',
                },
            ],
            improvements: [
                {
                    description:
                        'Les champs de facture QBO "Expédier via", "Date d\'expédition" et "Numéro de suivi" sont maintenant remplis automatiquement.',
                },
            ],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: 'Better error handling during order processing.',
                },
            ],
            improvements: [
                {
                    description:
                        'QBO invoice fields "Ship via", "Shipping date" and "Tracking No." are now filled automatically.',
                },
            ],
            newFeatures: [],
        },
    },
    {
        date: new Date('january 25 2023').getTime(), // 25 Janvier 2023
        fr: {
            fixes: [
                {
                    description: 'Les produits associés à des sous-compagnies sont maintenant supportés',
                },
            ],
            improvements: [
                {
                    description: [
                        'Révision majeure des interfaces de commandes et des correspondances. Le fonctionnement est très similaire, mais simplifié et les problèmes intermittents rapportés ont été corrigés.',
                        'Certains produits de type "Catégorie" et certaines taxes de type "Ajustement" ont été retirés de la liste des possibilités, car ils ne sont pas supportés sur des factures et posent problème.',
                    ],
                },
            ],
            newFeatures: [],
        },
        en: {
            fixes: [
                {
                    description: 'Products associated with sub-companies are now supported',
                },
            ],
            improvements: [
                {
                    description: [
                        'Major overhaul of the orders and mappings interfaces. Operation is very similar, but simplified, and reported intermittent issues have been fixed.',
                        'Products of type "Category" and Taxes of type "Adjustment" have been removed from the list of possibilities, because they are not supported on invoices and cause problems.',
                    ],
                },
            ],
            newFeatures: [],
        },
    },
];
